<template>
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo">
                <header-content-addition
                    :addition="headerInfo"
                    :has-filters="searchObject.preset === ''"
                    :filters-params-count="paramsCount"
                    @showFilters="onShowFilters"
                    @updateSearch="onUpdateSearch"
                />
            </header-content>

            <div
                ref="wrapper"
                class="wrapper wrapper--xs"
            >
                <!-- <ticket-filter
                    @updateSearch="onUpdateSearch"
                /> -->
                <section class="my-orders">
                    <the-filters
                        ref="filters"
                        :show-filter="showFilter"
                        :statuses="searchStatusOptionsWithCount"
                        :search-options="$options.searchOptions"
                        :default-filters-params="defaultFiltersParams"
                        @updateSearch="onUpdateSearch"
                        @updateParamsCounter="onUpdateParamsCount"
                        @closeFilters="onCloseFilters"
                    />
                    <tickets-presets
                        :presets="searchStatusOptionsWithCount"
                        :table-info="tableInfo"
                        :loading="loading"
                        @updateSearch="onUpdateSearch"
                    />
                </section>
                <div
                    v-if="tickets.length > 0 || loading"
                    class="radius-loader--wrapper"
                >
                    <div
                        v-if="loading"
                        class="background-for-radius-loader"
                        :class="{'full-height': !nextPageUrl}"
                    >
                        <img
                            src="@/assets/img/svg/animation/LoaderRadius.svg"
                            alt="loader"
                        >
                    </div>

                    <div
                        v-else
                        class="tickets_list"
                    >
                        <support-card
                            v-for="(ticket, index) in tickets"
                            :key="ticket.ticketid"
                            :data="ticket"
                            :index="index"
                        />
                    </div>
                </div>
            </div>
            <transition name="fade">
                <div
                    v-if="!tickets.length && !loading"
                    class="tickets-not-found"
                >
                    <not-found
                        text="You do not have any tickets yet."
                        desc="Go to the earnings section and check your balance."
                    />
                </div>
            </transition>
        </template>
    </main-layout>
</template>

<script>
import { eventBus } from '@/helpers/event-bus';
import { ticketsHeaderInfo } from '@/services/tickets/headerInfo.js'
import { getTickets, getStatusesWithCounter } from '@/services/tickets/ticketsService.js'
import { DEFAULT_FILTERS_PARAMS, searchOptions } from '@/constants/tickets/tickets.js'

// Components
import MainLayout from '@/layouts/MainLayout.vue'
import HeaderContent from '@/components/HeaderContent';
import HeaderContentAddition from '@/components/HeaderContentAddition';
import TheFilters from '@/components/common/Filters/TheFilters.vue';
import NotFound from '@/components/NotFound'
import TicketsPresets from '@/components/account/orders/OrderPresets.vue'
import SupportCard from '@/components/account/SupportCard/SupportCard';

const defaultQuery = {
    page: 1,
    preset: '',
    per_page: 10
}
export default {
    searchOptions,
    ticketsHeaderInfo,
    components: {
        MainLayout,
        HeaderContent,
        HeaderContentAddition,
        TheFilters,
        TicketsPresets,
        NotFound,
        SupportCard
    },
    metaInfo: {
        title: 'Help Center'
    },
    data() {
        return {
            loading: null,
            isFirstLoad: true,
            ticketsData: null,
            tickets: [],
            searchObject: defaultQuery,
            searchStatusOptionsWithCount: [],
            showFilter: false,
            ticketsCounters: null,
            paramsCount: 0
        }
    },
    computed: {
        defaultFiltersParams() {
            return DEFAULT_FILTERS_PARAMS
        },
        nextPageUrl() {
            return this.ticketsData?.next_page_url
        },
        tableInfo() {
            return [
                {
                    title: 'Count',
                    count: this.ticketsCounters?.count
                }
            ]
        },
        headerInfo() {
            return { ...this.$options.ticketsHeaderInfo, counter: this.ticketsCounters?.count }
        }
    },
    async created() {
        if (this.$route.query.ticketid !== undefined) {
            setTimeout(() => this.scrollToEndPage(), 100)
        }
        this.searchObject = {
            ...this.searchObject,
            ...this.$route.query,
            page: 1,
            per_page: +this.$route.query.per_page || 10
        }
        if (this.isFirstLoad) {
            this.isFirstLoad = false
        }
        this.routerReplacer()
        await this.getData()
    },
    methods: {
        incrementPage() {
            this.searchObject.page += 1
        },
        async getStatuses() {
            this.searchStatusOptionsWithCount = await getStatusesWithCounter();
        },
        async getData(cache = false, with_total = true) {
            try {
                this.loading = true
                const requestArr = [getTickets({ ...this.searchObject, mode: 'list' }, cache), getStatusesWithCounter()]
                if (with_total) {
                    requestArr.push(getTickets({ ...this.searchObject, mode: 'count' }, cache))
                }
                const allQuery = await Promise.all(requestArr)
                // eslint-disable-next-line prefer-destructuring
                this.ticketsData = allQuery[0]
                // eslint-disable-next-line prefer-destructuring
                this.searchStatusOptionsWithCount = allQuery[1]
                // eslint-disable-next-line prefer-destructuring
                this.ticketsCounters = allQuery[2]
                this.tickets = this.ticketsData.data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async loadMore() {
            this.loading = true
            this.incrementPage()
            const { data, next_page_url } = await getTickets({ ...this.searchObject, mode: 'list' }, false)
            this.tickets = [...this.tickets, ...data]
            this.ticketsData.next_page_url = next_page_url
            this.routerReplacer()
            this.loading = false
        },
        async onUpdateSearch({ query }) {
            if (query.preset) {
                this.$refs['filters'].clearForm()
            }
            this.searchObject = { ...defaultQuery, ...query }
            this.searchObject.page = 1
            await this.getData()
            this.routerReplacer()
        },
        routerReplacer() {
            if (JSON.stringify(this.searchObject) !== JSON.stringify(this.$route.query)) {
                this.$router.replace({ query: { ...this.searchObject } })
            }
        },
        onUpdateParamsCount(count) {
            this.paramsCount = count
        },
        onShowFilters(preset) {
            if (preset) {
                this.showFilter = !this.showFilter
            } else {
                this.searchObject.preset = ''
                this.onUpdateSearch({ query: this.searchObject })
                this.showFilter = true
            }
        },
        onCloseFilters() {
            this.showFilter = false
        },
        scrollToEndPage() {
            const elementWrapper = this.$refs.wrapper
            const positionToScroll = elementWrapper.getBoundingClientRect().bottom - elementWrapper.offsetHeight - 80
            window.scrollTo({ top: positionToScroll, behavior: 'smooth' });
        }
    }
}
</script>
<style lang="scss" scoped>
.tickets_list{
    margin-top: -30px;
}
.support__heading {
    padding: 65px 0 20px;

    .breadcrumbs-item a{
        font-size: 18px;
        color: $font-color-main;
    }
    .mdi-chevron-right {
        color: $font-color-main;
    }

    .orders-title h1 {
        font-size: 56px;
        color: $white;
    }
}

.tickets-not-found{
    margin: 100px 0;
}
</style>
