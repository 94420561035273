export const searchOptions = [
    {
        text: 'Title',
        value: 'title'
    },
    {
        text: 'Ticket ID',
        value: 'ticketid'
    },
    {
        text: 'Order ID',
        value: 'orderid'
    }
]

export const DEFAULT_FILTERS_PARAMS = {
    search_by: 'title',
    search_for: '',
    per_page: '10',
    from: '',
    to: ''
}
