export const ticketsHeaderInfo = {
    title: 'Help Center',
    btn_text: 'New support ticket',
    btn_link: 'support_form',
    className: 'header-content__account-setting header-content__tickets',
    steps: [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'Dashboard',
            url: '/cp/account'
        }
    ]
}
